import { Component, ElementRef, Input, ViewChild } from '@angular/core';

/**
 * Component allows specifying custom validation message for the specified
 * validator to override default message.
 *
 * Use this component as child in {@link NgxValidationMessagesComponent}.
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ngx-custom-message',
  template: ` <div #message>
    <ng-content></ng-content>
  </div>`,
  standalone: true,
})
export class NgxCustomMessageComponent {
  /**
   * The name of the validator for which you want to override the message.
   */
  @Input()
  public forValidator: string = '';

  /**
   * Contains overridden message for validator.
   */
  @ViewChild('message')
  public message!: ElementRef;
}
