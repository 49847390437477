<!--To show error messages standalone without component-->
<div
  *ngIf="!for && customMsgComponent.length === 0 && customMsgDirective.length === 0"
  [ngClass]="
    ngxValidationConfig.validationMessagesStyle?.blockClassNames ||
    'ngx_vm__def_error_block'
  ">
  <span
    [ngClass]="
      ngxValidationConfig.validationMessagesStyle?.textClassNames ||
      'ngx_vm__def_error_text'
    ">
    <ng-content></ng-content>
  </span>
</div>

<ng-container *ngIf="errorMessages.length > 0">
  <ng-container *ngIf="isMaterialError; else withoutMaterial">
    <ng-container *ngFor="let message of errorMessages">
      <mat-error>{{ message }}</mat-error>
    </ng-container>
  </ng-container>

  <ng-template #withoutMaterial>
    <div
      [ngClass]="
        ngxValidationConfig.validationMessagesStyle?.blockClassNames ??
        'ngx_vm__def_error_block'
      ">
      <div *ngFor="let message of errorMessages">
        <span
          [ngClass]="
            ngxValidationConfig.validationMessagesStyle?.textClassNames ??
            'ngx_vm__def_error_text'
          ">
          {{ message }}
        </span>
      </div>
    </div>
  </ng-template>
</ng-container>
